//import LangEn from "./en";
import { ReactNode } from "react";
import LangEn from "./en";
import LangHu from "./hu";

export const SupportedLanguages = [LangHu, LangEn] as const;

type Language = {
	imgAlt: string
	lang_name: string,
	cv_name: string,
	printLabel: string
	versionLabel: string,
	generateDateLabel: (link: string) => ReactNode,
	name: string,
	title: string,
	edu: string,
	major: string,
	gimi: string,
	grade: string,
	english: string,
	exp: string,
	expdata: string[],
	workplaces_title: string,
	workplaces: string[]
	projects: string,
	projectList: (JSX.Element | string)[]
	techs_title: string,
	techs: string
	missing: string
	
}


export default Language;