import CV from "CV/CV";
import LangHu from "languages/hu";
import Language, { SupportedLanguages } from "languages/skeleton";
import { useState } from "react";
import "./style.css";

const App = ({}) => {

	const [lang, setLang] = useState<Language>(LangHu)

	return (
		<>
			<div className="preheader">
				<div className="scrollbar">
					<div className="btn" onClick={window.print}>
					<i className="fa-solid fa-print" /><span>{lang.printLabel}</span>
					</div>
					{SupportedLanguages.map((lang,key) => (
						<div key={key} className="btn" onClick={() => setLang(lang)}>{lang.versionLabel}</div>
					))}
				</div>
			</div>
			<CV lang={lang} />
		</>
	)

};

export default App;