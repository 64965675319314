import { LinkIcon } from "utils";
import Language from "./skeleton"

const LangEn: Language = {
	imgAlt: "profile picture",
	lang_name: "en",
	cv_name: "CV",
	printLabel: "print / download",
	versionLabel: "🇬🇧 English version",
	techs_title: "technologies:",
	name: "Dominik Dudás",
	title: "Junior Software Developer",
	edu: "Education",
	exp: "Experiences",
	projects: "My bigger projects",
	techs: "Tecnologies",
	generateDateLabel: (link) => <span>This document was made on {new Date().toLocaleDateString()} Check out my website for the latest version: <a href={"https://"+link}>{link}</a></span>,
	"gimi": "High School",
	"english": "English advanced proficiency (C1)",
	"grade": "98% graded informatics final exam",
	//"auto": "Self learning (since 2013)",
	"missing": "Something is missing? Don't worry! Give me some time and I'll pick it up quickly.",
	"major": "Eötvös Loránd University - Factulty of Informatics. Computer Scientist BSc.",
	"workplaces_title": "Previous jobs",
	"workplaces": [
		"Telcotrend Kft.: internship (6 months)",
		"freelancing: full stack web development (since 2022. september)"
	],
	"expdata": [
		"full stack web development (React.js / Vue.js frontend, Java / Node.js backend)",
		"Java development (8+ years)",
		"running, hosting, developing Game servers (500+ online, 100.000+ registered users)",
		"Linux server management (Raspberry PI, Ubuntu/Debian VPS)",
		"experience in various AWS services",
		"Website & app hosting",
	],
	"projectList": [
		<span>
			<a href="https://www.barobor.hu">
				barobor.hu
				<LinkIcon />
			</a>
			Baró Bőr fullstack web application based on React.JS & Node.JS
		</span>,
		<span>
			<a href="https://setgame.dudasdominik.hu">
				Set game
				<LinkIcon />
			</a>
			online multiplayer implementation of the Set card game
		</span>,
		<span>
			<a href="https://rpg.dudasdominik.hu">
				BSc. thesis program
				<LinkIcon />
			</a>
			Development of a web based MMORPG game and its custom game engine
		</span>,
		<span>
			<a href="https://github.com/Kristall01/vidampark">
				amusement park tycoon
				<LinkIcon />
			</a>
			implementation of an amusement park tycoon simulator game in a university agile development group
		</span>
		//"Lagrange interpolator: egyszerű Lagrange interpolációs vizuális kalkulátor web alapon",
//		"c++ Minecraft szerver implementáció"
	],
/* 		"Sett: online multiplayer implementation of the Sett card game",
		"e-commerce webshop: fullstack web application based on React.JS & Node.JS",
		//"Lagrange interpolator: simple Lagrange interpolation visual calculator on web",
		"BSc. thesis program: development of a web based MMORPG game and its custom game engine",
		"amusement park tycoon: implementation of an amusement park tycoon simulator game in a university agile development group",
		//"c++ Minecraft server implementation"
	] */
}

export default LangEn;