import { ChildrenProps } from 'utils';

export type SectionProps = {
	title: string
} & ChildrenProps

const Section = ({children, title}: SectionProps) => {

	return (
		<div className="section">
			<div className="title">{title}</div>
			<div className="content">
				{children}
			</div>
		</div>
	)

};

export default Section;