import { LinkIcon } from "utils";
import Language from "./skeleton"

const LangHu: Language = {
	lang_name: "hu",
	cv_name: "önéletrajz",
	imgAlt: "profilkép",
	printLabel: "nyomtatás / letöltés",
	versionLabel: "🇭🇺 Magyar változat",
	generateDateLabel: (link) => <span>Ez a dokumentum {new Date().toLocaleDateString()} napon készült. A legfrissebb verzióért látogasson el a weboldalamra: <a href={"https://"+link}>{link}</a></span>,
	title: "Junior Szoftverfejlesztő",
	edu: "Oktatás",
	exp: "Tapasztalatok",
	projects: "Nagyobb projektjeim",
	techs: "Technológiák",
	gimi: "Szakgimnázium",
	english: "stabil nyelvtudás, Angol komplex C1-es nyelvvizsga",
	grade: "98%-ra értékelt emelt szintű szakmai érettségi vizsga",
	//"auto": "Autodidakta tanulás (2013 óta)",
	missing: "Hiányzik innen valami? Semmi gond! Adjon egy kis időt és gyorsan belejövök.",
	major: "ELTE IK Programtervező Informatikus BSc.",
	expdata: [
		"full stack web fejlesztés (React.js / Vue.js frontend, Java / Node.js backend)",
		"Java fejlesztés (8+ év)",
		"játék szerverek üzemeltetése, futtatása, fejlesztése (500+ online, 100.000+ regisztrált játékos)",
		"Linux szerver kezelés (Raspberry PI, Ubuntu/Debian VPS)",
		"többféle AWS szolgáltatás használatával kapcsolatos tapasztalat",
		"weboldal & alkalmazás hoszting",
	],
	"projectList": [
		<span>
			<a href="https://www.barobor.hu">
				barobor.hu
				<LinkIcon />
			</a>
			Baró Bőr e-commerce webshop fullstack webapp react & node alapokon
		</span>,
		<span>
			<a href="https://setgame.dudasdominik.hu">
				Set
				<LinkIcon />
			</a>
			Set kártyajáték webes online multiplayer implementációja
		</span>,
		<span>
			<a href="https://rpg.dudasdominik.hu">
				szakdolgozat
				<LinkIcon />
			</a>
			web alapú MMORPG játék és hozzá tartozó game engine kifejlesztése
		</span>,
		<span>
			<a href="https://github.com/Kristall01/vidampark">
				vidámpark tycoon
				<LinkIcon />
			</a>
			vidámpark tycoon játék szimulátor implementációja agilis módszertan alkalmazásával egyetemi csoportfeladat keretében
		</span>
		//"Lagrange interpolator: egyszerű Lagrange interpolációs vizuális kalkulátor web alapon",
//		"c++ Minecraft szerver implementáció"
	],
	name: "Dudás Dominik",
	techs_title: "Technológiák",
	workplaces_title: "Korábbi munkahelyek",
	workplaces: [
		"Telcotrend Kft.: szakmai gyakorlat (6 hónap)",
		"freelancing: full stack web fejlesztés (2022. szeptember óta)"
	]

}

export default LangHu;