import Contact from 'Contact/Contact';
import Language from 'languages/skeleton';
import { useEffect } from 'react';
import Section from './Section';

export type CVProps = {
	lang: Language
}

const link = "dudasdominik.hu/cv";

const CV = ({lang}: CVProps) => {

	document.body.parentElement?.setAttribute("lang", lang.lang_name);
	document.title = `${lang.name} - ${lang.cv_name}`;
		
	return (
		<div className="a4wrap">
			<div className="a4">
				<i id="generate">
					{lang.generateDateLabel(link)}
				</i>
				<div className="align">
					<div className="header">
						<div className="photo-block">
							<img className="photo" src={process.env["PUBLIC_URL"]+"/photo2.jpg"} alt={lang.imgAlt} />
						</div>
						<div className="header-remain">
							<div className="signature">
								<div className="name">{lang.name}</div>
								<div className="role">{lang.title}</div>
							</div>
							<div className="contact flextable">
								<div>
									<Contact icon="fa-regular fa-envelope" href="mailto:dudas.dominik@protonmail.com">
										dudas.dominik@protonmail.com
									</Contact>
									<Contact icon="fa-solid fa-phone" href="tel:+36-20/431-2555">
										+36-20/431-2555
									</Contact>
								</div>
								<div>
									<Contact icon="fa-brands fa-github" href="https://github.com/Kristall01">
										github.com/Kristall01
									</Contact>
									<Contact icon="fa-solid fa-earth-americas" href="https://www.dudasdominik.hu">
										www.dudasdominik.hu
									</Contact>
								</div>
							</div>
						</div>
					</div>
					<div className="main">
						<Section title={lang.edu}>
							<ul>
								<li>{lang.major}</li>
								<li>Neumann János {lang.gimi} Eger ({lang.grade})</li>
								<li>{lang.english}</li>
							</ul>
						</Section>
						<Section title={lang.exp}>
							<ul>
								{lang.expdata.map((a, b) => <li key={b}>{a}</li>)}
							</ul>
						</Section>
						<Section title={lang.workplaces_title}>
							<ul>
								{lang.workplaces.map((a,b) => <li key={b}>{a}</li>)}
							</ul>
						</Section>
						<Section title={lang.projects}>
							<ul>
								{lang.projectList.map((a, b) => <li key={b}>{a}</li>)}
							</ul>
						</Section>
						<Section title={lang.techs}>
							<ul>
								<li>web (backend): Java, Javalin NodeJS, Express.js, Typeorm, Sequelize.JS, Prisma</li>
								<li>web (frontend): vanilla, Typescript, React.JS, Vue.JS</li>
								<li>Java: Java SE, Gradle, Jetty, Javalin, Bukkit, IntelliJ, Eclipse, JDBC</li>
								<li>Devops: Git, Gitlab, Github</li>
								<li>Linux/hosting: Ubuntu/Debian server, Apache, Nginx, Docker</li>
								<li>SQL: mysql, phpmyadmin, postgres, sqlite, orm</li>
								<li>Cloud (AWS stack): S3, Cloudfront, Amplify, Lambda</li>
							</ul>
						</Section>
						<div className="missing-tech">{lang.missing}</div>
					</div>
				</div>
			</div>
		</div>
	)
		

};

export default CV;