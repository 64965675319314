import { ChildrenProps } from "utils";

export type ContactProps = {
	href: string,
	icon: string
} & ChildrenProps

const Contact = ({children, href, icon}: ContactProps) => {

	return (
		<div>
			<i className={icon}></i>
			<a href={href}>{children}</a>
		</div>
	)

};

export default Contact;